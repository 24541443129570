<script lang="ts" setup>
const props = defineProps<{ data: Array<any> }>();

const localePath = useLocalePath();
const mobileMenu = useMobileMenu();
const route = useRoute();

const { prices, rangeFilters, setParamsAndExecute } = await useCatalog();

const currentPath = computed(() => route.path);
const categorySlugList = await useCategories();
const links = computed(() => {
  const links = props.data.map(l => getLinkForType(l, localePath));

  for (const link of links) {
    link.click = async () => {
      // reset filters on navigation
      prices.value.min = 0;
      prices.value.max = 0;
      rangeFilters.value = [];

      if (currentPath.value === link.href) {
        const slug = link.href.split("/").findLast(p => p !== "")!;
        const categorySlugItem = categorySlugList.value && categorySlugList.value[slug];

        if (categorySlugItem !== undefined) {
          const resetParams = {
            categoryId: categorySlugItem.id,
            term: undefined,
            textFilters: undefined,
            rangeFilters: undefined,
          };

          await setParamsAndExecute(resetParams);
        }
      }
      else {
        await navigateTo(link.href);
      }

      mobileMenu.value.isOpen = false;
    };
  }

  return links;
});
</script>

<template>
  <div class="hidden bg-gray-50 py-2 text-sm lg:block">
    <div class="container mx-auto">
      <nav>
        <ul class="flex list-none flex-wrap items-center justify-between">
          <li v-for="link in links" :key="link?.id" class="mx-8 leading-4">
            <div v-if="!link!.subLinks">
              <LinkCustom class="block py-2" :data="link" />
            </div>

            <UDropdown
              v-if="link!.subLinks"
              :items="link!.subLinks as [][]"
              :popper="{ placement: 'bottom-start' }"
              mode="hover"
            >
              <LinkCustom class="block py-2" :data="link">
                <template #content-after>
                  <i class="pe-7s-angle-down pe-lg relative top-[2px]" />
                </template>
              </LinkCustom>
            </UDropdown>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<style scoped>

</style>
